<template>
  <button
    class="atom-button"
    :type="type"
    :disabled="isDisabled"
    :class="[
      `is-${colorSchema}`,
      `is-${variant}`,
      `is-icon-${iconPosition}`,
      { 'has-icon': icon !== 'none' },
      { 'is-inverted': isInverted },
    ]"
    @click="onClick"
  >
    <span
      v-if="variant !== 'icon' && variant !== 'plain-icon' && variant !== 'plain-icon-bordered'"
      class="atom-button__text"
    >{{ text }}</span>

    <IonIcon
      v-if="icon !== 'none'"
      :name="icon"
      :class="`is-${colorSchema}`"
      class="atom-button__icon"
    />
  </button>
</template>

<script setup>
const props = defineProps({
    /*
        Text
    */
    text: {
        type: String,
        default: 'Call to action',
        validator: (value) => value.length > 0,
    },

    type: {
        type: String,
        default: 'button',
        validator: (value) => [
            'button',
            'submit',
            'reset',
        ].includes(value),
    },

    /*
        Colors
    */
    colorSchema: {
        type: String,
        default: 'primary',
        validator: (value) => [
            'primary',
        ].includes(value),
    },

    isInverted: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Variants
    */
    variant: {
        type: String,
        default: 'rounded',
        validator: (value) => [
            'square', 'rounded', 'icon', 'plain', 'dashed', 'plain-icon', 'plain-icon-bordered', 'grey',
        ].includes(value),
    },

    icon: {
        type: String,
        default: 'none',
    },

    iconPosition: {
        type: String,
        default: 'right',
        validator: (value) => [
            'left',
            'right',
        ].includes(value),
    },

    /*
        States
    */
    isDisabled: {
        type: Boolean,
        default: false,
        validator: (value) => typeof value === 'boolean',
    },

    /*
        Functionality
    */
    callback: {
        type: Function,
        default: () => {},
    },
});

const onClick = () => {
    if (props.isDisabled) return;
    props.callback();
};

const justifyContent = computed(() => {
    if (props.icon === 'none') return 'center';
    return 'unset';
});
</script>

<style lang="scss">
.atom-button__text {
    z-index: 1;
}

.atom-button__icon {
    z-index: 1;
    transition: transform 0.3s var(--ease-in-out--sine);
}

.atom-button {
    @include fluid('--f-size--button', 18px, 18px);

    position: relative;
    display: flex;
    overflow: hidden;
    height: 60px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: v-bind(justifyContent);
    padding: 0px 59px;
    border: none;
    -webkit-appearance: none;
    color: var(--c-secondary);
    column-gap: 40px;
    font-family: var(--f-family--primary);
    font-size: var(--f-size--button);
    font-weight: var(--f-fw-primary--black);
    outline: none;
    transition: all 0.3s var(--ease-in-out--sine);
    white-space: nowrap;

    /*
        Variants
    */
    &.is-square {
        border-radius: 3px;

        &:after {
            border-radius: 3px;
        }
    }

    &.is-rounded {
        border-radius: 40px;

        &:after {
            border-radius: 40px;
        }
    }

    &.is-icon {
        display: flex;
        width: 60px;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 50%;
    }

    &.is-grey {
        border-radius: 40px;
    }

    .calendar-component__day &.is-icon {
        width: 24px;
        height: 24px;
        border: none;
        margin: auto 0;
        background: none;
        box-shadow: none;

        .atom-button__icon {
            path {
                stroke: var(--c-primary)!important;
            }
        }
    }

    &.is-plain {
        height: fit-content;
        align-items: center;
        padding: 0;
        column-gap: 16px;
        &:after {
            content: none
        }
    }

    &.has-icon:not(.is-icon):not(.is-plain):not(.is-dashed):not(.is-plain-icon):not(.is-plain-icon-bordered) {
        align-items: center;
        padding: 18px 30px;
        column-gap: 23px;

        &:after {
            border-radius: 40px;
        }
    }

    /*
        Colors
    */
    &.is-primary {
        border: 2px solid var(--c-primary);
        background: var(--c-primary);
        box-shadow: 0 6px 0 0 var(--c-secondary);
        color: var(--c-white);

        &.is-grey {
            color: var(--c-black);
            background-color: var(--c-white);
            box-shadow: none;
            border: 2px solid var(--c-grey);
            pointer-events: none;
        }

        &.is-plain {
            border: none;
            background: none;
            box-shadow: none;
            color: var(--c-primary);
            .atom-button__icon {
                path {
                    stroke: var(--c-primary);
                }
            }
        }

        &.is-dashed {
            align-items: center;
            padding: 18px 28px 18px 5px;
            border: 1px dashed var(--c-grey);
            border-radius: 30px;
            background: none;
            box-shadow: none;
            color: var(--c-grey);
            column-gap: 10px;

            .atom-button__icon {
                width: 50px;
                height: 50px;
            }
        }

        &.is-plain-icon {
            height: fit-content;
            padding: 0;
            border: none;
            background: none;
            box-shadow: none;
        }

        &.is-plain-icon-bordered {
            height: 40px;
            padding: 10px 20px;
            border: 2px solid var(--c-secondary);
            border-radius: 22px;
            background: none;
            box-shadow: none;
        }

        &.has-icon:not(.is-icon):not(.is-plain):not(.is-dashed):not(.is-plain-icon):not(.is-plain-icon-bordered) {
            .atom-button__icon {
                path {
                    fill: var(--c-white);
                    stroke: var(--c-white);
                }
            }
        }

        &.is-inverted {
            border: 2px solid var(--c-secondary);
            background: var(--c-white);
            box-shadow: 0 6px 0 0 var(--c-secondary);
            color: var(--c-secondary);

            &.is-icon {
                .atom-button__icon {
                    path {
                        fill: var(--c-primary);
                        stroke: var(--c-primary);
                    }
                }
            }

            &.is-plain {
                border: none;
                background: none;
                box-shadow: none;
                color: var(--c-secondary);
                .atom-button__icon {
                    path {
                        stroke: var(--c-secondary);
                    }
                }
            }

            &.is-dashed {
                align-items: center;
                padding: 18px 5px;
                border: 1px dashed var(--c-grey);
                border-radius: 30px;
                background: none;
                box-shadow: none;
                color: var(--c-grey);
                column-gap: 10px;

                .atom-button__icon {
                    width: 50px;
                    height: 50px;
                }
            }

            &.has-icon:not(.is-icon):not(.is-plain) {
                color: var(--c-primary);
                .atom-button__icon {
                    path {
                        fill: var(--c-primary);
                        stroke: var(--c-primary);
                    }
                }
            }
        }
    }

    &.is-secondary {
        &.is-plain {
            border: none;
            background: none;
            box-shadow: none;
            color: var(--c-secondary);

            .atom-button__icon {
                path {
                    stroke: var(--c-secondary);
                }
            }
        }
    }

    /*
        Icon
    */
    &.is-icon-left {
        .atom-button__icon {
            order: -1;
            margin-right: 10px;
        }
    }

    /*
        States
    */
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--c-primary--light);
        content: '';
        transform: translateX(-100%);
        transition: transform 0.6s var(--ease-in-out--quint);
    }

    &:not(.is-icon):not(.is-plain):not(.is-dashed):not(.is-plain-icon):not(.is-plain-icon-bordered):hover {
        border-color: var(--c-secondary);
        background: var(--c-secondary);
        box-shadow: unset;
        color: var(--c-primary);

        &:after {
            transform: translateX(0%);
        }

        .atom-button__icon {
            transform: translateX(8px);
        }
    }

    &:disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}

</style>
